import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { getAccessToken } from 'src/app/shared/auth-utils';
import { log } from 'src/app/shared/log';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    get loggedIn() {
        const token = getAccessToken();
        if (!token) {
            return false;
        }
        try {
            // Check that the token is valid and un-expired.
            const decoded = jwt_decode(token);
            const timestamp = new Date().valueOf() / 1000;
            return decoded['exp'] && decoded['exp'] > timestamp;
        } catch (e) {
            log.error(e);
            return false;
        }
    }

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
        private api: ApiService,
    ) {
        this.start();
    }

    public isValid: boolean;
    public token: string;

    ngOnDestroy() {
        this.stop();
    }

    login(username, pass, rememberMe: boolean) {
        return this.http
            .post(environment.apiUrl + '/login', {
                username: username,
                password: pass,
            })
            .pipe(
                map((result) => {
                    this.applyToStorage(result, rememberMe);
                    return result;
                }),
            );
    }

    signOut() {
        this.cookieService.delete('loginCookie');
        window.localStorage.setItem('sign-out-event', Math.random().toString());
        window.dispatchEvent(
            new StorageEvent('storage', {
                storageArea: localStorage,
                key: 'sign-out-event',
            }),
        );
        this.api.reset();
        // This makes sure things are cleared out from the previous user.
        window.location.replace('/login');
    }

    getLoginCookie(): string {
        return this.cookieService.get('loginCookie');
    }

    applyToStorage(result, rememberMe: boolean) {
        const storage = rememberMe ? localStorage : sessionStorage;
        const user = result['user'] ? result['user'] : result;

        storage.setItem('token', result.token);
        storage.setItem('user', user.username);
        storage.setItem('username', user.name);
        storage.setItem('userRole', user.user_role);
        storage.setItem('userId', user.user_id);
        storage.setItem('customerId', user.customer_id);
        storage.setItem('customerRole', user.customer_role);
        storage.setItem('oemCustomerId', user.oem_id);
        storage.setItem('customer_prefix', user.customer_prefix);
        storage.setItem('applicationTitle', user.title);
    }

    // Bind the eventListener
    private start(): void {
        window.addEventListener(
            'storage',
            this.storageEventListener.bind(this),
        );
    }

    // Handle active listeners when onDestroy
    private stop(): void {
        window.removeEventListener(
            'storage',
            this.storageEventListener.bind(this),
        );
    }

    // Clear storage when the key is 'sign-out-event'
    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event?.key && event.key == 'sign-out-event') {
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigate(['/']);
            }
        }
    }
}
